.title {
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
}

.controlTitle {
  font-weight: normal;
  font-size: 12px;
}
