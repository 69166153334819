.button {
  width: 100%;
  padding: 0.9em 1.3em;
  color: #1e1e1e;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  background: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 2px;
  cursor: pointer;

  &:disabled {
    color: rgb(200, 200, 200);
    cursor: default;
  }

  &:hover:enabled {
    border-color: rgb(150, 150, 150);
  }

  &:active:enabled {
    color: #fff;
    background-color: #49494d;
  }

  &.redTheme {
    color: #fff;
    background-color: rgb(217, 42, 33);
    border: 1px solid rgb(217, 42, 33);
    transition: background-color 0.2s ease-in-out 0s, color 0.2s ease-in-out 0s,
      border-color 0.2s ease-in-out 0s;

    &:hover:enabled,
    &:active:enabled {
      background-color: rgb(166, 32, 25);
      border-color: rgb(166, 32, 25);
    }

    &:disabled {
      color: #b9b2b2;
      background: #fff;
      border: 1px solid #e6e6e6;
    }
  }
}
