@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rightPanel {
  overflow-y: auto;
  background-color: #fff;
  border-left: 1px solid rgb(230, 230, 230);
}

.tabs {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.tabsList {
  display: flex;
  flex-shrink: 0;
  justify-content: space-evenly;
  font-family: 'Poppins', sans-serif;
  background-color: #f8f8fa;
}

.tab {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 17px 0;
  color: #969696;
  font-weight: 600;
  font-size: 12px;
  line-height: 1.5;
  text-transform: uppercase;
  cursor: pointer;
  transition: color 0.2s ease-in-out 0s;

  &:hover {
    color: #000;
  }
}

.selectedTab {
  color: #000;
  background-color: #fff;
}

.disabledTab {
  cursor: initial;

  &:hover {
    color: #969696;
  }
}

.arrowIcon {
  transform: rotate(270deg);
  color: var(--accent-color);
}

.tabPanelContainer {
  flex: 1;
  overflow-y: auto;
}

.artworks {
  padding: 25px 54px;
}

.artwork {
  display: flex;
  padding: 7px;
  color: rgb(60, 60, 60);
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  word-break: break-all;
  border: 1px solid transparent;
  border-radius: 2px;

  &:hover {
    background-color: rgb(248, 248, 250);
  }

  &:not(:first-child) {
    margin-top: 10px;
  }
}

.selectedArtwork {
  border: 1px solid rgb(30, 30, 30);
}

.artworkThumbnail {
  width: 54px;
  height: 54px;
  margin-right: 15px;
  border: 1px solid #d9d9d9;
}

.templatesListItem,
.briefTemplateButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 24px 25px 40px;
  padding-left: 26px;
  color: #353535;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  cursor: pointer;

  &:hover {
    background-color: rgb(248, 248, 250);
  }

  &.selected {
    background-color: var(--accent-color);
    color: var(--canvas-color);
    font-weight: 600;
  }
}

.briefTemplateButton {
  width: 100%;
  border: initial;
  border-bottom: 1px solid #e6e6e6;
}

.duplicateButton {
  flex: 1;

  &:nth-child(2) {
    margin-left: 14px;
  }
}

.productsList {
  max-height: 400px;
  overflow-y: auto;
}

.renderButton {
  width: 100%;
  margin-bottom: 10px;
}

.adjustSection {
  padding: 25px 26px;
  border-bottom: 1px solid #e6e6e6;
}

.adjustSectionHeader {
  margin-bottom: 20px;
  color: #353535;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  text-align: left;
}

.producePNGPreviewError,
.producePNGPreviewMessage {
  margin-bottom: 10px;
  color: #d92a21;
  font-weight: 400;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  text-align: left;
}

.producePNGPreviewMessage {
  color: #353535;
}

.insertionPointControls {
  margin-bottom: 10px;
}
