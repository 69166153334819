.modeSwitch {
  display: flex;
  flex-direction: column;
}

.modeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
  color: rgb(255, 255, 255);
  background-color: rgb(150, 150, 150);
  border: initial;
  border-radius: 2px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out 0s;

  &:hover {
    background-color: rgb(60, 60, 60);
  }

  &.activeModeButton {
    background-color: rgb(30, 30, 30);

    .moveIcon,
    .rotateIcon {
      path {
        color: rgb(217, 42, 33);
      }
    }
  }
}

.moveIcon {
  font-size: 34px;
}

.rotateIcon {
  font-size: 41px;
}
