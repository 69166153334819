.editor {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 100%;
  height: 100vh;
}

.content {
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
  height: 100%;
  display: flex;
  padding: 20px;
  overflow: auto;
}

.controlPanel {
  flex-basis: 350px;
  flex-shrink: 0;
}

.goBackLink {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 120;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  text-decoration: none;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(230, 230, 230);
  border-radius: 2px;
  cursor: pointer;

  &:hover,
  &:focus {
    border-color: rgb(150, 150, 150);
  }
}

.goBackIcon {
  font-size: 12px;
  transform: rotate(90deg);
  color: var(--accent-color);
}
