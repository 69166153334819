@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.handle {
  position: relative;
  top: 1px;
  display: block;
  width: 18px;
  flex-shrink: 0;
  height: 11px;
  margin: 0 7px;
  background: linear-gradient(
    180deg,
    #000,
    #000 20%,
    #fff 0,
    #fff 40%,
    #000 0,
    #000 60%,
    #fff 0,
    #fff 80%,
    #000 0,
    #000
  );
  cursor: row-resize;
  opacity: 0.25;
}

.product {
  display: flex;
  align-items: center;
  padding: 7px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  border: 1px solid transparent;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  &:hover {
    border-color: var(--accent-color);
  }
}

.productName {
  color: #000;
}

.selectedProduct {
  border-color: #929090;
}

.selectedObject {
  border-color: var(--accent-color);
}

.outOfSelection {
  opacity: 0.4;
}

.productMainContent {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px;
}

.productThumbnail {
  width: 50px;
  height: 50px;
  margin-right: 14px;
  border: 1px solid #d9d9d9;
}

.copyIconButton {
  @include flex-center();

  background-color: transparent;
  height: 43px;
  flex: 0 0 43px;
  border-radius: 2px;
  border: 1px solid transparent;
  cursor: pointer;
  transition: border-color 0.3s;

  &:hover {
    border-color: #e6e6e6;
  }

  &:active {
    color: var(--canvas-color);
    background-color: var(--accent-color);
    border-color: transparent;
    transition: none;
  }
}

.copyIcon {
  width: 22px;
  height: 22px;
}
