.chooseBackground {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.previouslyUploadedBackgrounds {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.previouslyUploadedBackgrounds,
.previouslyUploadedBackgroundsHeader {
  font-family: 'Poppins', sans-serif;
  line-height: 1.3;
  text-align: center;
}

.previouslyUploadedBackgroundsHeader {
  margin-bottom: 10px;
  font-weight: normal;
  font-size: 14px;
  text-align: left;
}

.backgroundsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, 120px);
  padding-top: 10px;
  overflow-y: auto;
  column-gap: 20px;
  list-style: none;
  row-gap: 20px;
}

.backgroundsGridItem {
  position: relative;
}

.backgroundThumbnail {
  width: 120px;
  height: 90px;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #e8e9e8;
  cursor: pointer;

  &:hover {
    border-color: #c58280;
  }

  &.disabled {
    opacity: 0.4;
    cursor: default;
    &:hover {
      border-color: #e8e9e8;
    }
  }
}

.infoMessage {
  margin-top: 10px;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.3;
  text-align: center;
}
