.slider {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.baseSlider {
  flex: 1;

  :global(.rc-slider-rail) {
    height: 1px;
    background-color: rgb(230, 230, 230);
  }

  :global(.rc-slider-track) {
    height: 1px;
    background-color: rgb(30, 30, 30);
  }

  :global(.rc-slider-handle) {
    width: 20px;
    height: 20px;
    margin-top: -9px;
    background-color: rgb(200, 200, 200);
    border: initial;
    border-radius: 50%;
  }

  // FIXME: for some reason handle's box shadow width with tooltip is bigger than when slider without tooltip
  :global(.rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging) {
    border-color: none;
    box-shadow: none;
  }
}

.handle {
  width: 20px;
  height: 20px;
  margin-top: -9px;
  background-color: rgb(200, 200, 200);
  border: initial;
  border-radius: 50%;
}

.input {
  width: 55px;
  margin-right: 5px;
  margin-left: 25px;
  font-weight: 400;
  font-size: 13px;
  font-family: Poppins, sans-serif;
  line-height: 18px;
}

.percentInputContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(30, 30, 30);
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}

.percentInput {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  padding: 0 12px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(230, 230, 230);
  border-radius: 2px;
}
