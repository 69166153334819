:global(#root) {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
}

.app {
  width: 100%;
  height: 100%;
}

.loader {
  min-height: 200px;
}

.message {
  margin: 40px 20%;
  font-family: 'Poppins', sans-serif;
  line-height: 1.3;
  text-align: center;
}

@mixin hide-arrows-from-number-input {
  // https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    margin: 0;
    appearance: none;
  }

  /* Firefox */
  /* stylelint-disable-next-line selector-no-qualifying-type  */
  input[type='number'] {
    appearance: textfield;
  }
}

@include hide-arrows-from-number-input;
