.checkbox {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 1.2em;
  color: #49494d;
  font-weight: 500;
  font-size: 12px;

  font-family: 'Poppins', sans-serif;
  font-style: normal;
}

.input {
  position: absolute;
  appearance: none;
  appearance: none;
  appearance: none;

  &:checked + .box {
    background-image: url('~assets/icons/checkbox-on.svg');
  }
}

.box {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-left: -1.2em;
  background-image: url('~assets/icons/checkbox-off.svg');
}

.label {
  margin-left: 13px;
}
