.backgroundUploadForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin-top: 44px;
  font-family: 'Poppins', sans-serif;
}

.inputFile {
  position: absolute;
  z-index: -1;
  width: 0.1px;
  height: 0.1px;
  overflow: hidden;
  opacity: 0;
}

.backgroundUploadButton {
  max-width: 215px;
  margin-bottom: 10px;
}

.error {
  color: rgb(217, 42, 33);
  font-size: 14px;
}
