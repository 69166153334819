.templatesList {
  height: 100%;
  flex: 1;
  padding: 78px 136px;
}

.templateGroupName {
  margin: 0;
  margin-bottom: 15px;
  font-family: 'Poppins', sans-serif;
}

.templates {
  display: grid;
  grid-template-columns: repeat(auto-fill, 120px);
  column-gap: 20px;
  row-gap: 20px;
}

.templateThumbnail {
  width: 120px;
  height: 90px;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #e8e9e8;

  &:hover {
    border-color: #c58280;
  }
}

.selectedTemplate {
  border-color: #929090;
}

.noTemplatesMessage {
  margin: 40px 20%;
  font-family: 'Poppins', sans-serif;
  line-height: 1.3;
  text-align: center;
}
