.limitedInputControls {
  display: flex;
  justify-content: center;
  color: #1e1e1e;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}

.limitedInputControl {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  font-weight: 600;
  background-color: #fff;
  border-radius: 0;
}

.displayValue {
  font-size: 12px;
  border: none;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  font-family: inherit;
  text-align: center;
}

.arrowIcon {
  font-size: 10px;
  color: #1e1e1e;

  &.disabled {
    color: #c8c8c8;
  }
}

.arrowLeft {
  transform: rotate(90deg);
}

.arrowRight {
  transform: rotate(270deg);
}
