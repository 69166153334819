*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  text-size-adjust: 100%;
}

html {
  touch-action: manipulation; // https://developers.google.com/web/updates/2013/12/300ms-tap-delay-gone-away
}

html,
body {
  width: 100%;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  appearance: none;
}
