.control {
  margin-bottom: 10px;
  text-align: center;
}

.controlTitle {
  margin-bottom: 10px;
  font-weight: normal;
  font-size: 12px;
}

.layerSetsActions {
  display: flex;
  justify-content: space-between;
}

.layerSetAction {
  width: calc(100% / 2 - 5px);
}
