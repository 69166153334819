.controlTitle {
  font-weight: normal;
  font-size: 12px;
}

.productControlsPanel {
  padding-bottom: 15px;
}

.productControl {
  margin: 0 auto;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.backgroundInfluenceCheckbox {
  margin-bottom: 10px;
}

.backgroundInfluenceCheckboxLabel {
  font-size: 12px;
}

.backgroundBaseColorControl {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.backgroundBaseColor {
  margin-right: 10px;
  font-weight: normal;
  font-size: 12px;
}
