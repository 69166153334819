.sceneContainer {
  width: 100%;
  height: 100%;
  display: flex;
}

.scene {
  position: relative;
  margin: auto;
  overflow: hidden;
  background-color: #f2f2f2;
  border: 1px solid #cecece;

  &.fitWidth {
    width: 100%;
  }

  &.fitHeight {
    height: 100%;
  }
}

.backgroundLayer {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.boundaries {
  position: absolute;
  border: 1px dashed rgb(0, 128, 0);
}

.selectionLayer {
  position: absolute;
  top: 0;
  left: 0;
}

.selectionArea {
  position: absolute;
  z-index: 1;

  &.dragging {
    cursor: move;
  }
}

.viewerContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sceneLoaderLayer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 15;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.interactionModeSwitch {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 14;
}

.sceneLoader {
  height: 100%;
}
