.title {
  margin-bottom: 18px;
  color: rgb(60, 60, 60);
  font-weight: 500;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  line-height: 24px;
  text-align: center;
}

.inputLabel {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  text-align: left;
}

.input {
  display: block;
  width: 100%;
  height: 38px;
  padding: 0 12px;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(230, 230, 230);
  border-radius: 2px;
}

.textarea {
  display: block;
  width: 100%;
  height: 200px;
  max-height: 120px;
  padding: 0 12px;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(230, 230, 230);
  border-radius: 2px;
  resize: none;
}

.errorMessage {
  margin-bottom: 10px;
  color: #d92a21;
  font-weight: 400;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  text-align: left;
}

.actions {
  display: flex;
  justify-content: space-between;
  width: 320px;
  margin: 0 auto;
}

.actionButton {
  width: 150px;
}
