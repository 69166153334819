.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.75);
}

.modal {
  position: absolute;
  width: 420px;
  margin: auto;
  padding: 32px 20px 20px;
  overflow: auto;
  background-color: green;
  background-color: rgb(255, 255, 255);
  border: 1px solid #ccc;
  border-radius: 4px;

  border-radius: 5px;
  outline: none;
  -webkit-overflow-scrolling: touch;
}
